import { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Navigation } from '@/components/Navigation'
import { StaticImage } from 'gatsby-plugin-image'
import leaf from '@/images/leaf-footer.svg'
import fpLogo from '@/images/fp-logo.svg'
import { MinimalFooter } from '@/components/MinimalFooter'
import { HeadProps, Link } from 'gatsby'
import { Contact } from '@/components/Contact'
import { SEO } from '@/components/Seo'
import { Banner } from '@/components/Banner'

export const Head = (props: HeadProps) => (
  <SEO
    pathname={props.location.pathname}
    title="Luxury Wedding Florist | London & Surrey"
  />
)

export default function Home() {
  return (
    <div className="relative overflow-hidden bg-background">
      <Banner />
      <header className="relative overflow-hidden">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 bg-background pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-48">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-background lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <Navigation />

            <div className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="mt-4 font-serif text-4xl tracking-tight text-gray-900 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl sr-only">
                  <span className="block">Flowers by Primrose</span>
                </h1>
                <p className="mt-3 font-light tracking-tight text-base text-gray-900 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-md lg:mx-0 lg:w-3/4">
                  Primrose is a floral designer based in South-West London.
                  Primrose tells stories with flowers and creates dreamy and
                  romantic arrangements, whether it's a simple and stunning
                  vision you have or a grand and lavish one.
                </p>
                <p className="mt-3 font-light tracking-tight text-base text-gray-900 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-md lg:mx-0 lg:w-3/4">
                  If you would like to elevate your special occasion with
                  stunning and seasonal flowers, please contact us and we'll be
                  in touch shortly.
                </p>
                <div className="mt-8">
                  <a
                    href="#get-in-touch"
                    className="inline-block rounded-md border border-transparent bg-phathloGreen py-3 px-8 font-bold uppercase tracking-widest text-white hover:ring-2 hover:ring-offset-2 hover:ring-phathloGreen text-xs"
                  >
                    Get in touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            className="h-96 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="../images/home-hero.jpg"
            alt=""
          />
        </div>
      </header>
      <main>
        <MyStory />
        <Services />
        <Contact />
      </main>

      <MinimalFooter />
    </div>
  )
}

const MyStory = () => (
  <div className="bg-white">
    <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <img className="h-16 object-contain mx-auto" src={leaf} alt="" />
        <h2 className="font-serif text-4xl tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
          About Primrose
        </h2>
        <div className="mx-auto mt-8 max-w-2xl font-light text-base text-gray-900 leading-relaxed">
          <p>
            South-West London-raised Primrose has always had a love for all
            things floral. Initially started her career in the cooperate world
            working for leading Homewares and Fashion companies; Primrose brings
            a wealth of transferable knowledge to the world of flowers. Primrose
            creates stunning arrangements for weddings, events, and private
            clients.
          </p>
          <p className="mt-4">
            Flowers by Primrose was founded at the start of 2022 following a
            McQueens Floristry course and working in a premium florist store.
            Primrose prides herself on providing a personal and professional
            service, creating beautiful and bespoke floral designs for all
            occasions, and helping you choose the right flowers to bring your
            imagination to life.
          </p>
        </div>
      </div>
    </div>
  </div>
)

const Services = () => (
  <div className="relative bg-phathloGreen grid grid-cols-1 sm:grid-cols-3">
    <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
      <div className="md:w-2/3 mx-auto text-center">
        <div className="px-8">
          <StaticImage
            className="mx-auto"
            src="../images/weddings.png"
            alt=""
          />
        </div>
        <p className="mt-8 font-serif text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Weddings
        </p>
        <p className="mt-8 text-base text-gray-300">
          Primrose is here to help you tell your unique story through
          extraordinary and memorable floral designs.
        </p>
        <div className="mt-8">
          <div className="inline-flex">
            <Link
              to="weddings"
              className="relative inline-flex uppercase tracking-widest items-center justify-center py-1 text-xs font-extrabold text-white hover:text-gray-300 after:content-[''] after:h-[1px] after:bg-white after:block after:absolute after:w-full after:top-[100%]"
            >
              Find out more
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:pb-32 lg:pt-8 bg-background w-full min-h-[500px]">
      <div className="absolute inset-0">
        <StaticImage
          className="w-full h-full sm:h-72 md:h-96 lg:h-full lg:w-full"
          imgClassName="object-cover"
          src={`../images/middle-home.png`}
          alt=""
        />
      </div>
      <div className="p-8 absolute inset-x-0 top-0 lg:w-2/3">
        <img className="w-full object-contain" src={fpLogo} alt="" />
      </div>
    </div>

    <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32 flex bg-ashGray text-gray-900">
      <div className="mx-auto text-center mt-auto">
        <StaticImage
          className="mx-auto rounded-full md:w-2/3"
          src="../images/wreath.png"
          alt=""
        />
        <p className="mt-8 font-serif text-3xl font-bold tracking-tight sm:text-4xl">
          Classes &amp; Workshops
        </p>
        <div className="md:w-2/3 text-center mx-auto">
          <p className="mt-8 text-base text-gray-900">
            It&apos;s a great way to celebrate a special birthday, anniversary,
            or just because!
          </p>
          <div className="mt-8">
            <div className="inline-flex">
              <a
                href="https://www.designmynight.com/london/whats-on/activity/christmas-wreath-making-bubbles"
                target="_blank"
                className="relative inline-flex uppercase tracking-widest items-center justify-center py-1 text-xs font-extrabold  hover:text-gray-300 after:content-[''] after:h-[1px] after:bg-black after:block after:absolute after:w-full after:top-[100%]"
              >
                Find out more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
